import { createRouter, createWebHistory } from 'vue-router'

//function loadView(view) {
//  return () => import(/* webpackChunkName: "view-[request]Router" */ `@/views/${view}.vue`)
//}

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/layout/AppLayout.vue'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'Inicio',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/inicio/Index.vue'),
            meta: {
              title: 'Inicio',
              footer: 'bg-secondary'
            }
          },
          {
            path: '/inicio',
            name: 'Home',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/inicio/Index.vue'),
            meta: {
              title: 'Inicio',
              footer: 'bg-secondary'
            }
          },
          {
            path: 'terminos-condiciones',
            name: 'terminos.index',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/terminos/Index.vue'),
            meta: {
              title: 'Términos y condiciones'
            },
          },
          {
            path: 'aviso-privacidad',
            name: 'privacidad.index',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/privacidad/Index.vue'),
            meta: {
              title: 'Aviso de privacidad'
            },
          },
          {
            path: '/inicio/inversionista',
            name: 'inversionista.index',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/inicio/Inversionista.vue'),
            meta: {
              title: 'Invierte',
              footer: 'bg-primary'
            }
          },
          {
            path: '/inicio/pool',
            name: 'pool.index',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/inicio/Pool.vue'),
            meta: {
              title: 'Pool',
              footer: 'bg-secondary'
            }
          },
          {
            path: '/inicio/vehiculos',
            name: 'vehiculos.index',
            component: () => import(/* webpackChunkName: "InicioRouter" */'@/views/inicio/Vehiculos.vue'),
            meta: {
              title: 'Vehículos',
              footer: 'bg-secondary'
            }
          },
        ]
      },
      {
        path: '/precalifique',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'precalifique.index',
            component: () => import(/* webpackChunkName: "PrecalifiqueRouter" */'@/views/precalifique/Index.vue'),
            meta: {
              title: 'Solicita tu préstamo',
              footer: 'bg-primary'
            }
          },
          {
            path: '/solicita-prestamo/vehiculos',
            name: 'precalifique.vehiculos.index',
            component: () => import(/* webpackChunkName: "PrecalifiqueRouter" */'@/views/precalifique/vehiculos/Index.vue'),
            meta: {
              title: 'Solicita tu préstamo',
              footer: 'bg-primary'
            }
          },
          
          {
            path: '/solicita-prestamo/vehiculos/gracias',
            name: 'vehiculos.gracias',
            component: () => import(/* webpackChunkName: "PrecalifiqueRouter" */'@/views/precalifique/vehiculos/Gracias.vue'),
            meta: {
              title: 'Gracias',
              footer: 'bg-primary'
            }
          },
        ]
      },
      {
        path: '/opciones-inversion/:id/premium',
        name: 'opciones-inversion.premium',
        component: () => import(/* webpackChunkName: "OpcionesInversionRouter" */'@/views/opciones-inversion/Premium.vue'),
        meta: {
          title: 'Convertir en Premium',
          footer: 'bg-secondary'
        }
      },
      {
        path: '/opciones-inversion',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'opciones-inversion.index',
            component: () => import(/* webpackChunkName: "OpcionesInversionRouter" */'@/views/opciones-inversion/Index.vue'),
            meta: {
              title: 'Opciones de inversión',
              footer: 'bg-secondary'
            },
          },
          {
            path: '/opciones-inversion/:id',
            name: 'opciones-inversion.show',
            component: () => import(/* webpackChunkName: "OpcionesInversionRouter" */'@/views/opciones-inversion/Show.vue'),
            meta: {
              title: 'Ver inversión',
              footer: 'bg-secondary'
            },
            children: [
              {
                path: '/opciones-inversion/:id/propuesta',
                name: 'opciones-inversion.propuesta',
                component: () => import(/* webpackChunkName: "OpcionesInversionRouter" */'@/views/opciones-inversion/Propuesta.vue'),
                meta: {
                  title: 'Propuesta',
                  footer: 'bg-secondary'
                }
              },

              {
                path: '/opciones-inversion/:id/detalle',
                name: 'opciones-inversion.detalle',
                component: () => import(/* webpackChunkName: "OpcionesInversionRouter" */'@/views/opciones-inversion/Detalle.vue'),
                meta: {
                  title: 'Detalles',
                  footer: 'bg-secondary'
                }
              },
            ]
          },
        ]
      },
      {
        path: '/vehiculos',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'vehiculos.muro',
            component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos/Index.vue'),
            meta: {
              title: 'Vehículos',
              footer: 'bg-secondary'
            }
          },
          {
            path: '/vehiculos/nuevos',
            name: 'vehiculos.nuevos',
            component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos-nuevos/Index.vue'),
            meta: {
              title: 'Vehículos nuevos',
              footer: 'bg-primary'
            }
          },
          {
            path: '/vehiculos/crear',
            name: 'vehiculos.create',
            component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos/Create.vue'),
            meta: {
              requiresAuth: false,
              title: 'Crear vehículo',
              footer: 'bg-secondary'
            }
          },
          {
            path: '/vehiculos/:id',
            name: 'vehiculos.show',
            component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos/Show.vue'),
            meta: {
              title: 'Vehículo',
              footer: 'bg-secondary'
            },
            children: [
              {
                path: '/vehiculos/:id/ofertar',
                name: 'vehiculos.ofertar',
                component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos/Ofertar.vue'),
                meta: {
                  title: 'Vehículo',
                  footer: 'bg-secondary'
                }
              },
              {
                path: '/vehiculos/:id/contactar',
                name: 'vehiculos.contactar',
                component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos/Contactar.vue'),
                meta: {
                  title: 'Contactar',
                  footer: 'bg-secondary'
                }
              },
              {
                path: '/vehiculos/:id/me-interesa',
                name: 'vehiculos.me-interesa',
                component: () => import(/* webpackChunkName: "VehiculosRouter" */'@/views/vehiculos/Opciones.vue'),
                meta: {
                  title: 'Me interesa',
                  footer: 'bg-secondary'
                }
              },
            ]
          },
          {
            path: '/solicita-prestamo/auto-compras',
            name: 'precalifique.auto-compras.index',
            component: () => import(/* webpackChunkName: "PrecalifiqueRouter" */'@/views/precalifique/auto-compras/Index.vue'),
            meta: {
              title: 'Solicita tu préstamo',
              footer: 'bg-primary'
            }
          },
          {
            path: '/solicita-prestamo/auto-compras-nuevos',
            name: 'precalifique.auto-compras-nuevos.index',
            component: () => import(/* webpackChunkName: "PrecalifiqueRouter" */'@/views/precalifique/auto-compras-nuevos/Index.vue'),
            meta: {
              title: 'Solicita tu préstamo',
              footer: 'bg-primary'
            }
          },
          {
            path: '/solicita-prestamo/auto-compras/gracias',
            name: 'auto-compras.gracias',
            component: () => import(/* webpackChunkName: "PrecalifiqueRouter" */'@/views/precalifique/auto-compras/Gracias.vue'),
            meta: {
              title: 'Gracias',
              footer: 'bg-primary'
            }
          },
        ]
      },
      {
        path: '/requisitos',
        name: 'requisitos.index',
        component: () => import(/* webpackChunkName: "RequisitosRouter" */'@/views/requisitos/Index.vue'),
        meta: {
          title: 'Requisitos',
          footer: 'bg-primary'
        }
      },
      {
        path: '/formularios-test/hipotecarios/:id',
        name: 'formularios.hipotecarios',
        component: () => import(/* webpackChunkName: "FormulariosHipotecariosRouter" */'@/views/formularios/Hipotecarios.vue'),
        meta: {
          title: 'Formulario hipotecarios',
          footer: 'bg-primary'
        }
      },
      {
        path: '/formularios-test/relojes/:id',
        name: 'formularios.relojes',
        component: () => import(/* webpackChunkName: "FormulariosHipotecariosRouter" */'@/views/formularios/Relojes.vue'),
        meta: {
          title: 'Formulario relojes',
          footer: 'bg-primary'
        }
      },
      {
        path: '/formularios-test/vehiculos/:id',
        name: 'formularios.vehiculos',
        component: () => import(/* webpackChunkName: "FormulariosHipotecariosRouter" */'@/views/formularios/Vehiculos.vue'),
        meta: {
          title: 'Formulario vehículos',
          footer: 'bg-primary'
        }
      },
      {
        path: '/beneficios',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'beneficios.index',
            component: () => import(/* webpackChunkName: "BeneficiosRouter" */'@/views/beneficios/Beneficios.vue'),
            meta: {
              title: 'Beneficios',
              footer: 'bg-primary'
            }
          },
          {
            path: '/beneficios/:id',
            name: 'beneficios.show',
            component: () => import(/* webpackChunkName: "BeneficiosRouter" */'@/views/beneficios/BeneficiosShow.vue'),
            meta: {
              title: 'Beneficio',
              footer: 'bg-primary'
            }
          },
          {
            path: '/beneficios/cupones/:id',
            name: 'beneficios.cupon',
            component: () => import(/* webpackChunkName: "BeneficiosRouter" */'@/views/beneficios/BeneficiosCupon.vue'),
            meta: {
              title: 'Cupón',
              footer: 'bg-primary'
            }
          },
        ]
      },
      {
        path: '/contacto',
        name: 'contacto.index',
        component: () => import(/* webpackChunkName: "CashinRouter" */'@/views/contacto/Index.vue'),
        meta: {
          title: 'Contacto',
          footer: 'bg-primary'
        }
      },
      {
        path: '/nosotros',
        name: 'nosotros.index',
        component: () => import(/* webpackChunkName: "CashinRouter" */'@/views/nosotros/Index.vue'),
        meta: {
          title: 'Nosotros',
          footer: 'bg-secondary'
        }
      },
      {
        path: '/ingresar',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'login',
            component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Login.vue'),
            meta: {
              title: 'Ingresar',
              footer: 'bg-primary'
            },
          },
          {
            path: '/logout',
            name: 'logout',
            component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Logout.vue'),
            meta: {
              title: 'Cerrando sesión',
              footer: 'bg-primary'
            }
          },
          {
            path: '/recuperacion',
            name: 'reset',
            component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Reset.vue'),
            meta: {
              title: 'Recuperar contraseña',
              footer: 'bg-primary'
            }
          },
          {
            path: '/recuperacion/:id',
            name: 'reset.update',
            component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Recovery.vue'),
            meta: {
              title: 'Recuperar contraseña',
              footer: 'bg-primary'
            }
          },
        ]
      },
      {
        path: '/registrarse',
        name: 'register',
        component: () => import(/* webpackChunkName: "Auth" */'@/views/auth/Register.vue'),
        meta: {
          title: 'Registrate',
          footer: 'bg-primary'
        }
      },
      {
        path: '/predios/:id',
        name: 'admin.predios.show',
        component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/predios/Show.vue'),
        meta: {
          title: 'Predio',
          templateHide: true
        }
      },
      {
        path: '/admin',
        component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/Menu.vue'),
        children: [
          {
            path: '',
            name: 'admin.index',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/Index.vue'),
            meta: {
              requiresAuth: true,
              title: 'Perfil'
            }
          },
          {
            path: '/admin/agenda',
            name: 'admin.agenda',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/agenda/Index.vue'),
            meta: {
              requiresAuth: true,
              title: 'Agenda'
            }
          },

          {
            path: '/admin/predios',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/predios/Component.vue'),
            children: [
              {
                path: '',
                name: 'admin.predios',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/predios/Index.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Predios'
                }
              },
              {
                path: '/admin/predios/crear',
                name: 'admin.predios.create',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/predios/Create.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Agregar predio'
                }
              },
              {
                path: '/admin/predios/:id/editar',
                name: 'admin.predios.edit',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/predios/Edit.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Editar predio'
                }
              },
            ]
          },

          {
            path: '/admin/marcas',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/marcas/Component.vue'),
            children: [
              {
                path: '',
                name: 'admin.marcas',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/marcas/Index.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Marcas'
                }
              },
              {
                path: '/admin/marcas/crear',
                name: 'admin.marcas.create',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/marcas/Create.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Agregar marca'
                }
              },
              {
                path: '/admin/marcas/:id/editar',
                name: 'admin.marcas.edit',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/marcas/Edit.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Editar marca'
                }
              },
            ]
          },

          {
            path: '/admin/modelos',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/modelos/Component.vue'),
            children: [
              {
                path: '',
                name: 'admin.modelos',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/modelos/Index.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Modelos'
                }
              },
              {
                path: '/admin/modelos/crear',
                name: 'admin.modelos.create',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/modelos/Create.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Agregar modelo'
                }
              },
              {
                path: '/admin/modelos/:id/editar',
                name: 'admin.modelos.edit',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/modelos/Edit.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Editar modelo'
                }
              },
            ]
          },

          {
            path: '/admin/vehiculos',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculos/Menu.vue'),
            children: [
              {
                path: '',
                name: 'admin.vehiculos.pendientes',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculos/Pendientes.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Mis vehículos pendientes'
                }
              },
              {
                path: '/admin/vehiculos/aprobadas',
                name: 'admin.vehiculos.aprobadas',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculos/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Mis vehículos aprobados'
                }
              },
              {
                path: '/admin/vehiculos/rechazadas',
                name: 'admin.vehiculos.rechazadas',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculos/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Mis vehículos rechazados'
                }
              }
            ]
          },

          {
            path: '/admin/vehiculos-nuevos',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculosNuevos/Menu.vue'),
            children: [
              {
                path: '',
                name: 'admin.vehiculos-nuevos',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculosNuevos/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Vehículos nuevos aprobados'
                }
              },
              {
                path: '/admin/vehiculos-nuevos/rechazadas',
                name: 'admin.vehiculos-nuevos.rechazadas',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculosNuevos/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Vehículos rechazados'
                }
              },
            ]
          },
          {
            path: '/admin/vehiculos-nuevos/crear',
            name: 'admin.vehiculos-nuevos.create',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/vehiculosNuevos/Create.vue'),
            meta: {
              requiresAuth: true,
              title: 'Vehículo nuevo'
            }
          },

          {
            path: '/admin/apartados',
            component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/apartados/Menu.vue'),
            children: [
              {
                path: '',
                name: 'admin.apartados.pendientes',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/apartados/Pendientes.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Apartados pendientes'
                }
              },
              {
                path: '/admin/apartados/aprobadas',
                name: 'admin.apartados.aprobadas',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/apartados/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Apartados aprobados'
                }
              },
              {
                path: '/admin/apartados/rechazadas',
                name: 'admin.apartados.rechazadas',
                component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/apartados/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Apartados rechazados'
                }
              },
            ]
          },
        ]
      },
      {
        path: '/admin/apartados/:id/',
        name: 'admin.apartados.show',
        component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/admin/apartados/Show.vue'),
        meta: {
          requiresAuth: true,
          title: 'Solicitud para apartar vehículo'
        }
      },
      {
        path: '/perfil',
        component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/Menu.vue'),
        children: [
          {
            path: '',
            name: 'perfil.index',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/Index.vue'),
            meta: {
              requiresAuth: true,
              title: 'Perfil'
            }
          },
          {
            path: '/perfil/anuncios',
            name: 'perfil.anuncios',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/Anuncios.vue'),
            meta: {
              requiresAuth: true,
              title: 'Anuncios'
            }
          },
          {
            path: '/perfil/prestamos',
            name: 'perfil.prestamos',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/Prestamos.vue'),
            meta: {
              requiresAuth: true,
              title: 'Préstamos'
            }
          },
          {
            path: '/perfil/documentos',
            name: 'perfil.documentos',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/Documentos.vue'),
            meta: {
              requiresAuth: true,
              title: 'Documentos'
            }
          },
          {
            path: '/perfil/favoritos',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/favoritos/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.favoritos.prendas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/favoritos/Prendas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Prendas favoritas'
                }
              },
              {
                path: '/perfil/favoritos/usuarios',
                name: 'perfil.favoritos.usuarios',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/favoritos/Usuarios.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Usuarios favoritos'
                }
              },
              {
                path: '/perfil/favoritos/negocios',
                name: 'perfil.favoritos.negocios',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/favoritos/Negocios.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Negocios favoritos'
                }
              },
            ]
          },
          {
            path: '/perfil/mis-propuestas',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispropuestas/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.mispropuestas.autorizadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispropuestas/Autorizadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Propuestas autorizadas'
                }
              },
              {
                path: '/perfil/mis-propuestas/en-espera',
                name: 'perfil.mispropuestas.espera',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispropuestas/Espera.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Propuestas en espera'
                }
              },
              {
                path: '/perfil/mis-propuestas/denegadas',
                name: 'perfil.mispropuestas.denegadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispropuestas/Denegadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Propuestas denegadas'
                }
              },
            ]
          },
          {
            path: '/perfil/mis-publicaciones',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispublicaciones/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.mispublicaciones.autorizadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispublicaciones/Autorizadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Publicaciones autorizadas'
                }
              },
              {
                path: '/perfil/mis-publicaciones/en-espera',
                name: 'perfil.mispublicaciones.espera',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispublicaciones/Espera.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Publicaciones en espera'
                }
              },
              {
                path: '/perfil/mis-publicaciones/denegadas',
                name: 'perfil.mispublicaciones.denegadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispublicaciones/Denegadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Publicaciones denegadas'
                }
              },
              {
                path: '/perfil/mis-publicaciones/finalizadas',
                name: 'perfil.mispublicaciones.finalizadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/mispublicaciones/Finalizadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Publicaciones finalizadas'
                }
              },
            ]
          },
          {
            path: '/perfil/ofertas-recibidas',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasrecibidas/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.ofertasrecibidas.pendientes',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasrecibidas/Pendientes.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas pendientes'
                }
              },
              {
                path: '/perfil/ofertas-recibidas/aprobadas',
                name: 'perfil.ofertasrecibidas.aprobadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasrecibidas/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas aprobadas'
                }
              },
              {
                path: '/perfil/ofertas-recibidas/rechazadas',
                name: 'perfil.ofertasrecibidas.rechazadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasrecibidas/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas rechazadas'
                }
              },
            ]
          },
          {
            path: '/perfil/mis-vehiculos',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misVehiculos/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.misVehiculos.pendientes',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misVehiculos/Pendientes.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Mis vehículos'
                }
              },
              {
                path: '/perfil/mis-vehiculos/aprobadas',
                name: 'perfil.misVehiculos.aprobadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misVehiculos/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Vehículos aprobados'
                }
              },
              {
                path: '/perfil/mis-vehiculos/rechazadas',
                name: 'perfil.misVehiculos.rechazadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misVehiculos/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Vehículos rechazados'
                }
              },
              {
                path: '/perfil/mis-vehiculos/buscamos-tu-auto-por-ti',
                name: 'perfil.misVehiculos.preferencias',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misVehiculos/Preferencias.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Buscamos tu auto por ti'
                }
              },
            ]
          },
          {
            path: '/perfil/ofertas-vehiculos-realizadas',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misOfertasVehiculos/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.misOfertasVehiculos.pendientes',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misOfertasVehiculos/Pendientes.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas'
                }
              },
              {
                path: '/perfil/ofertas-vehiculos-realizadas/aprobadas',
                name: 'perfil.misOfertasVehiculos.aprobadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misOfertasVehiculos/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas aprobadas'
                }
              },
              {
                path: '/perfil/ofertas-vehiculos-realizadas/rechazadas',
                name: 'perfil.misOfertasVehiculos.rechazadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/misOfertasVehiculos/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas rechazadas'
                }
              },
            ]
          },
          {
            path: '/perfil/ofertas-vehiculos',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasVehiculos/Menu.vue'),
            children: [
              {
                path: '',
                name: 'perfil.ofertasVehiculos.pendientes',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasVehiculos/Pendientes.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas'
                }
              },
              {
                path: '/perfil/ofertas-vehiculos/financiamiento',
                name: 'perfil.ofertasVehiculos.financiamiento',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasVehiculos/Financiamiento.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'En proceso de financiamiento'
                }
              },
              {
                path: '/perfil/ofertas-vehiculos/aprobadas',
                name: 'perfil.ofertasVehiculos.aprobadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasVehiculos/Aprobadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas aprobadas'
                }
              },
              {
                path: '/perfil/ofertas-vehiculos/rechazadas',
                name: 'perfil.ofertasVehiculos.rechazadas',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/ofertasVehiculos/Rechazadas.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Ofertas rechazadas'
                }
              },
            ]
          },
          {
            path: '/perfil/estadisticas',
            name: 'perfil.estadisticas',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/Estadisticas.vue'),
            meta: {
              requiresAuth: true,
              title: 'Estadisticas'
            }
          },
          {
            path: '/perfil/mis-cupones',
            name: 'perfil.mis-cupones',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/MisCupones.vue'),
            meta: {
              requiresAuth: true,
              title: 'Mis cupones'
            }
          },
          {
            path: '/perfil/cuadro-financiero',
            name: 'perfil.cuadro-financiero',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/CuadroFinanciero.vue'),
            meta: {
              requiresAuth: true,
              title: 'Cuadro financiero'
            }
          },
          {
            path: '/perfil/sugerencias',
            name: 'perfil.sugerencias',
            component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/sugerencias/Index.vue'),
            meta: {
              requiresAuth: true,
              title: 'Sugerencias'
            },
            children: [
              {
                path: '/perfil/sugerencias/create',
                name: 'perfil.sugerencias.create',
                component: () => import(/* webpackChunkName: "PerfilRouter" */'@/views/perfil/sugerencias/Create.vue'),
                meta: {
                  requiresAuth: true,
                  title: 'Registrar sugerencia'
                }
              },
            ]
          },
        ]
      },
      {
        path: '/publicacion',
        name: 'publicacion.create',
        component: () => import(/* webpackChunkName: "PublicacionRouter" */'@/views/publicacion/Create.vue'),
        meta: {
          requiresAuth: true,
          title: 'Agregar publicación'
        }
      },
      {
        path: '/publicacion/:id',
        name: 'publicacion.show',
        component: () => import(/* webpackChunkName: "PublicacionRouter" */'@/views/publicacion/Show.vue'),
        meta: {
          requiresAuth: true,
          title: 'Publicación'
        }
      },
      {
        path: '/usuarios/:id',
        name: 'usuarios.show',
        component: () => import(/* webpackChunkName: "AdminRouter" */'@/views/usuarios/Show.vue'),
        meta: {
          requiresAuth: true,
          title: 'Usuario'
        }
      },
      {
        path: '/propuestas/:id',
        name: 'propuestas.show',
        component: () => import(/* webpackChunkName: "PropuestasRouter" */'@/views/propuestas/Show.vue'),
        meta: {
          requiresAuth: true,
          title: 'Propuesta'
        },
        children: [
          {
            path: '/propuestas/:id/editar',
            name: 'propuestas.edit',
            component: () => import(/* webpackChunkName: "PropuestasRouter" */'@/views/propuestas/Edit.vue'),
            meta: {
              requiresAuth: true,
              title: 'Editar propuesta'
            }
          },
        ]
      },

      {
        path: '/tiendas',
        component: () => import(/* webpackChunkName: "DefaultRouterRouter" */'@/views/DefaultRouter.vue'),
        children: [
          {
            path: '',
            name: 'tiendas.index',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/Index.vue'),
            meta: {
              title: 'CRECE TU TIENDA',
              footer: 'bg-primary'
            }
          },
          {
            path: '/tiendas/crear',
            name: 'tiendas.create',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/Create.vue'),
            meta: {
              title: 'Solicitud de préstamo',
              footer: 'bg-primary'
            }
          },
          {
            path: '/tiendas/:id',
            name: 'tiendas.show',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/Show.vue'),
            meta: {
              title: 'Ver solicitud',
              footer: 'bg-primary'
            }
          },

          {
            path: '/tiendas/precompra/',
            name: 'tiendas.precompra.proveedores',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/precompra/Proveedores.vue'),
            meta: {
              title: 'PRE COMPRA',
              footer: 'bg-primary'
            }
          },

          {
            path: '/tiendas/precompra/:proveedor',
            name: 'tiendas.precompra.categorias',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/precompra/Categorias.vue'),
            meta: {
              title: 'Categorías',
              footer: 'bg-primary'
            }
          },

          {
            path: '/tiendas/precompra/:proveedor/:categoria/productos',
            name: 'tiendas.precompra.productos',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/precompra/productos/Index.vue'),
            meta: {
              title: 'Productos',
              footer: 'bg-primary'
            }
          },

          {
            path: '/tiendas/precompra/productos/:producto',
            name: 'tiendas.precompra.productos.show',
            component: () => import(/* webpackChunkName: "TiendasRouter" */'@/views/tiendas/precompra/productos/Show.vue'),
            meta: {
              title: 'Ver producto',
              footer: 'bg-primary'
            }
          },
        ]
      }
    ]
  },
  {
    path: '/leads/auto-compras',
    component: () => import(/* webpackChunkName: "LeadsRouter" */'@/views/leads/AutoCompras.vue'),
    name: 'leads.auto-compras',
    meta: {
      title: 'Solicita tu préstamo',
      footer: 'bg-primary'
    }
  },
  {
    path: '/leads/autocompras',
    component: () => import(/* webpackChunkName: "LeadsRouter" */'@/views/leads/AutoCompras.vue'),
    name: 'leads.auto-compras2',
    meta: {
      title: 'Solicita tu préstamo',
      footer: 'bg-primary'
    }
  },
  {
    path: '/leads/inversionista',
    name: 'leads.inversionista',
    component: () => import(/* webpackChunkName: "LeadsRouter" */'@/views/leads/Inversionista.vue'),
    meta: {
      title: 'Invierte',
      footer: 'bg-primary'
    }
  },
  //{ path: '*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return new Promise(resolve => {
        setTimeout(() => {
          var el = document.querySelector(to.hash)
          if (el) {
            var scrolledY = el.offsetTop
            var yourHeight = 10;
            
            el.scrollIntoView({behavior: 'smooth'})
            if(scrolledY){
              //window.scroll(0, scrolledY-yourHeight)
              window.scroll({ top: (scrolledY-yourHeight), behavior: "smooth" });
            }
            //el.scrollIntoView({top: y, behavior: 'smooth'});
          }
          resolve({ selector: to.hash });
        }, 500);
      });
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
