<template>
  <router-view></router-view>
</template>
<script type="text/javascript">
  const facebookPixel = () => import(/* webpackChunkName: "facebook-pixel" */'@/facebook-pixel.js')

  export default {
    name: 'app',
    metaInfo: {
      meta: [
        {
          hid: "shortlink",
          name:  "shortlink",
          content: "https://www.clubcashin.com/"
        },
        {
          hid: "canonical",
          name:  "canonical",
          content: "https://www.clubcashin.com/"
        },
        {
          hid: "robots",
          name:  "robots",
          content: "max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        },
        {
          hid: "description",
          name:  "description",
          content: "Plataforma digital que conecta personas que desean invertir con personas que quieren créditos para comprar vehículos o sobre vehículos"
        },
        {
          hid: "og:locale",
          name:  "og:locale",
          content: "es_ES"
        },
        {
          hid: "og:type",
          name: "og:type",
          content: "website"
        },
        {
          hid: "og:title",
          name: "og:title",
          content: "Club Cashin"
        },
        {
          hid: "og:image",
          name: "og:image",
          content: "https://www.clubcashin.com/image/favicon_dark.webp?v2",
          itemprop: 'image'
        },
        {
          hid: "og:image:width",
          name: "og:image:width",
          content: "399"
        },
        {
          hid: "og:image:height",
          name: "og:image:height",
          content: "399"
        },
        {
          hid: "og:description",
          name: "og:description",
          content: "Conectamos a personas que buscan un préstamo garantizado por un carro, casa u otro inmueble con personas que quieren prestar su dinero"
        },
        {
          hid: "og:url",
          name: "og:url",
          content: "https://www.clubcashin.com/info"
        },
        {
          hid: "og:site_name",
          name: "og:site_name",
          content: "Club Cashin"
        }
      ],
    },

    mounted(){
      facebookPixel()/*.then((facebookPixel) => { facebookPixel() })*/

      if (this.isLoggedIn) {
        this.getCurrentUser()
      }
      window.addEventListener('resize', this.onResize, {passive: true})
      window.addEventListener('offline', () => {
        this.$store.commit('online', false)
      }, {passive: true})

      window.addEventListener('online', () => {
        this.$store.commit('online', true)
      }, {passive: true})
    },
    unmounted(){
      window.removeEventListener('resize', this.onResize)
    },
    methods:{
      getCurrentUser(){
        this.$store.dispatch('getCurrentUser').then(response => {
          return response
        }).catch(error =>{
          return error
        }).then(() => {
          this.loadingTipos = false
        })
      },

      onResize(){
        this.$store.commit('windowWidth', window.innerWidth)
        //let vh = window.innerHeight * 0.01
        //document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
    computed: {
      isLoggedIn(){
        return this.$store.getters.isLoggedIn
      }
    }
  }
</script>
<style lang="scss">
  @import "./assets/style/main.scss";
</style>