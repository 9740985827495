import { createStore } from 'vuex'
import {getTokenApi, getItems} from "./helpers"

const token = getTokenApi()
const itemsStorage = getItems()

import axios from 'axios'
if (process.env.NODE_ENV === 'production') {
	axios.defaults.baseURL = process.env.NODE_SERVER_PROXY || 'https://clubcashin.com';
}else{
	axios.defaults.baseURL = process.env.NODE_SERVER_PROXY || 'https://clubcashin.com';
	//axios.defaults.baseURL = process.env.NODE_SERVER_PROXY || 'http://172.168.1.106:8083'; //
}
if (token) {
	axios.defaults.headers.common['Authorization'] = 'p '+token;
}

export default createStore({
	state: {
		footerBG: 'text-white bg-primary',
		errors: {},
		loading: false,
		isLoggedIn: !!token,
		currentUser: {},
		token: token,
		mobileMenu: false,
		windowWidth: window.innerWidth || 0,
		beneficios: [],
		tiposBeneficios: [],
		categorias: [],
		tipos: [],
		publicaciones: [],
		publicacion: {},
		publicacionFotos: {},
		anuncios: [],
		documentos: [],
		prestamos: [],
		sugerencias: [],
		misPropuestas: [],
		misPublicaciones: [],
		propuesta: {},
		ofertasRecibidas: [],
		misVehiculos: [],
		apartados: [],
		ofertasVehiculos: [],
		predios: [],
		infoVehiculo: {},
		isMobile: false,
		online: true,

		marcas: [],
		modelos: [],
		lineas: [],
		colores: [],
		tiposVehiculos: [],

		tiendasSolicitudes: [],

		items: itemsStorage,
	},
	getters: {
		footerBG(state){
			return state.footerBG
		},
		online(state){
			return state.online
		},

		loading(state){
			return state.loading
		},

		currentUser(state){
			return state.currentUser
		},

		isLoggedIn(state){
			return state.isLoggedIn
		},

		errors(state){
			return state.errors
		},

		token(state){
			return state.token
		},

		items(state){
			return state.items
		},

		mobileMenu(state){
			return state.mobileMenu
		},

		isMobile(state){
			if(state.windowWidth>=992){
				return false
			}else{
				return true
			}
		},

		beneficios(state){
			return state.beneficios
		},

		tiposBeneficios(state){
			return state.tiposBeneficios
		},
		categorias(state){
			return state.categorias
		},
		tipos(state){
			return state.tipos
		},
		publicaciones(state){
			return state.publicaciones
		},
		publicacion(state){
			return state.publicacion
		},
		publicacionFotos(state){
			return state.publicacionFotos
		},
		anuncios(state){
			return state.anuncios
		},
		documentos(state){
			return state.documentos
		},
		prestamos(state){
			return state.prestamos
		},
		sugerencias(state){
			return state.sugerencias
		},
		misPropuestas(state){
			return state.misPropuestas
		},
		misPublicaciones(state){
			return state.misPublicaciones
		},
		propuesta(state){
			return state.propuesta
		},
		ofertasRecibidas(state){
			return state.ofertasRecibidas
		},
		misVehiculos(state){
			return state.misVehiculos
		},
		apartados(state){
			return state.apartados
		},
		ofertasVehiculos(state){
			return state.ofertasVehiculos
		},
		predios(state){
			return state.predios
		},
		infoVehiculo(state){
			return state.infoVehiculo
		},
		windowWidth(state){
			return state.windowWidth
		},
		marcas(state){
			return state.marcas
		},
		modelos(state){
			return state.modelos
		},
		lineas(state){
			return state.lineas
		},
		colores(state){
			return state.colores
		},
		tiposVehiculos(state){
			return state.tiposVehiculos
		},

		tiendasSolicitudes(state){
			return state.tiendasSolicitudes
		},
	},
	mutations: {
		addItems(state, payload){
			const items = state.items
			const index = items.findIndex(n => n.codigo === payload.codigo)

			if (index>-1) {
				items.splice(index, 1, payload)
			}else{
				items.push(payload)
			}

			state.items = items
			localStorage.setItem("items", JSON.stringify(items))
	    },

	    removeItems(state, payload){
	    	const items = state.items
	    	const index = items.findIndex(n => n.codigo === payload)
	    	if (index>-1) {
	    		items.splice(index, 1)
	    	}
	    	state.items = items
	    	localStorage.setItem("items", JSON.stringify(items))
	    },

	    removeAllItems(state){
	    	state.items = []
	    	localStorage.removeItem('items')
	    },

		footerBG(state, payload){
			state.footerBG = payload
		},
		online(state, payload){
			state.online = payload
		},

		errors(state, payload){
			state.errors = payload
		},
		windowWidth(state, payload){
        	state.windowWidth = payload
      	},
		showLoading(state){
			state.loading = true
		},
		hideLoading(state){
			state.loading = false
		},
		currentUser(state, payload) {
			state.currentUser = payload
		},
		loginSuccess(state, payload) {
			if (payload.token) {
            	state.token = payload.token
            	axios.defaults.headers.common["Authorization"] = 'p '+state.token
            	localStorage.setItem("token", state.token)
	            state.errors = {}
	            state.isLoggedIn = true
	            //state.currentUser = payload
			}
        },
        logout(state) {
            localStorage.removeItem("token")
            state.token = null
            state.errors = null
            state.isLoggedIn = false
            state.currentUser = {}
        },
        beneficios(state, payload){
        	state.beneficios = payload
        },
        tiposBeneficios(state, payload){
        	state.tiposBeneficios = payload
        },
        categorias(state, payload){
        	state.categorias = payload
        },
        tipos(state, payload){
        	state.tipos = payload
        },
        mobileMenu(state, payload){
        	state.mobileMenu = payload
        },
        publicaciones(state, payload){
        	state.publicaciones = payload
        },
        publicacion(state, payload){
        	state.publicacion = payload
        },
        publicacionFotos(state, payload){
        	state.publicacionFotos = payload
        },
        anuncios(state, payload){
        	state.anuncios = payload
        },
        documentos(state, payload){
        	state.documentos = payload
        },
        prestamos(state, payload){
        	state.prestamos = payload
        },
        sugerencias(state, payload){
        	state.sugerencias = payload
        },
        misPropuestas(state, payload){
        	state.misPropuestas = payload
        },
        misPublicaciones(state, payload){
        	state.misPublicaciones = payload
        },
        propuesta(state, payload){
        	state.propuesta = payload
        },
        ofertasRecibidas(state, payload){
        	state.ofertasRecibidas = payload
        },
        misVehiculos(state, payload){
        	state.misVehiculos = payload
        },
        ofertasVehiculos(state, payload){
        	state.ofertasVehiculos = payload
        },
        predios(state, payload){
        	state.predios = payload
        },
        infoVehiculo(state, payload){
        	state.infoVehiculo = payload
        },
        apartados(state, payload){
        	state.apartados = payload
        },
        marcas(state, payload){
			state.marcas = payload
		},
		modelos(state, payload){
			state.modelos = payload
		},
		lineas(state, payload){
			state.lineas = payload
		},
		colores(state, payload){
			state.colores = payload
		},
		tiposVehiculos(state, payload){
			state.tiposVehiculos = payload
		},
		tiendasSolicitudes(state, payload){
			state.tiendasSolicitudes = payload
		}
	},
	actions: {
		login(context, params={}) {
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/auth/login'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("loginSuccess", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		resetPassword(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/mails/sendRecovery'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updatePassword(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/user/updatePass'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getTokenRecovery(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/user/getTokenRecovery'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		register(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/auth/register'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		setSolicitudInv(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/setSolicitudInv'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		registroExitoso(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/mails/registroExitoso'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getCurrentUser(context, params={}) {
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/getMe'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("currentUser", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getBeneficios(context, params={}) {
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/beneficios/getBeneficios'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("beneficios", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getTiposBeneficios(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tipoNegocios/getTipoNegocioFilter'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("tiposBeneficios", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getCategorias(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getCategories2'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("categorias", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getTipos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getTipos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("tipos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicacionesDisp(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getPublicacionesDisp'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("publicaciones", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicacionesDisp2(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getPublicacionesDisp2'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("publicaciones", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicaciones(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/getPublicaciones'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("misPublicaciones", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicacionCount(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getPublicacionesDispCount'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("publicacionCount", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicacion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getDetailBasic'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("publicacion", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicacionFotos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/publicacion/getDetailPhoto'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("publicacionFotos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		publicacionUpdateStatus(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/updateStatus'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		publicacionUpdate(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/publicacionUpdate'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		sendAprobacion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/mails/sendAprobacion'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getAnuncios(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/crm/dinamico'
				params.url = '/api/publicaciones/portal'
				axios.post(url, params).then(res => {
					context.commit("anuncios", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addOportunidadVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/crm/dinamico'
				params.url = 'api/cashin/oportunidad/vehiculos'
				axios.post(url, params).then(res => {
					//context.commit("documentos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getDocumentos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/crm/dinamico'
				params.url = 'api/documentos/portal'
				axios.post(url, params).then(res => {
					context.commit("documentos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPrestamos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/crm/dinamico'
				params.url = '/api/clientes/prestamos/portal'
				axios.post(url, params).then(res => {
					context.commit("prestamos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPrerequisitos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/crm/dinamico'
				params.url = 'api/oportunidades/'+params.oportunidad+'/prerequisitos/portal'
				axios.post(url, params).then(res => {
					//context.commit("prerequisitos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getIntereses(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/getIntereses'
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addContacto(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/mails/mensajeContacto'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addPrecalificacion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/mails/addPrecalifica'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addInteres(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/addInteres'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		deleteInteres(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/deleteInteres'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updateProfile(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/updateProfile'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addPrerequisito(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/crm/uploadFile'
				//delete params['url']
				axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		uploadFile(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var modulo = params.get('modulo')
				
				var url = params.url || `/apiv2/uploads/${modulo}`
				delete params['url']
				axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		
		vehiculosUploadInspeccion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/uploadInspeccion'
				delete params['url']
				axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		inmueblesUploadInspeccion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/inmuebles/uploadInspeccion'
				delete params['url']
				axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updatePictureSelfDpi(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/updatePictureSelfDpi'
				delete params['url']
				axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getSugerencias(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tickets/get'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("sugerencias", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addPublicacion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/add'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPublicacionesFav(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/getPublicacionesFav'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getFavoriteUsers(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/getFavoriteUsers'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getNegUsers(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/getNegUsers'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getCuadroFinancieroData(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/getCuadroFinanUsuarios'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getTotalMoneyUser(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getTotalMoneyUser'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getReceivedMoney(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getReceivedMoney'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInvertedMoney(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getInvertedMoney'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getPropByInvMonth(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getPropByInvMonth'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getMyProposal(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getMyProposal'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("misPropuestas", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getReceivedProposal(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getReceivedProposal'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("ofertasRecibidas", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getCountPubUserMonth(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/getCountPubUserMonth'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getCountPubUser(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/getCountPubUser'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getUserSelected(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/getUserSelected'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getUsuarios(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/manage/getUsuarios'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getPublicacionesByUser(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/getPublicacionesByUser'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getProposalDetailInv(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getProposalDetailInv'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("propuesta", res.data[0] || {})
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getProposalDetail(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getProposalDetail'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("propuesta", res.data[0] || {})
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getQuestionProposal(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/getQuestionProposal'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addResponse(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/addResponse'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addQuestion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/addQuestion'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updateStatus(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/updateStatus'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		approveProposal(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/mails/approveProposal'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		sendPush(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/mails/sendPush'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		sendProposal(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/sendProposal'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updatePropuesta(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/proposal/updatePropuesta'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		mailsSendProposal(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/mails/sendProposal'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		deleteFavorite(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/deleteFavorite'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addFavorite(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/addFavorite'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		prendaDeleteFavorite(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/deleteFavorite'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		prendaAddFavorite(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/publicacion/addFavorite'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getMisCupones(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/beneficios/getMisCupones'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getCupon(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/beneficios/getCupon'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addSugerencia(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tickets/add'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addCupones(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/beneficios/addCupones'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculosGetModelos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getModelos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("modelos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculosGetTipos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getTipos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("tiposVehiculos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculosGetColores(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getColores'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("colores", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getColoresByVehiculo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getColoresVehiculo'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("colores", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		updateColorVehiculo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/updateColorVehiculo'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("colores", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		vehiculosGetMarcas(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getMarcas'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("marcas", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getMarcas(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosMarcas/getMarcas'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("marcas", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		addMarca(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosMarcas/addMarca'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		updateMarca(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosMarcas/updateMarca'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		deleteMarca(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosMarcas/deleteMarca'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getModelos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosModelos/getModelos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("modelos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		addModelo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosModelos/addModelo'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		updateModelo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosModelos/updateModelo'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		deleteModelo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosModelos/deleteModelo'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		
		getLineas(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosLineas/getLineas'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("lineas", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		addLinea(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosLineas/addLinea'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		updateLinea(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosLineas/updateLinea'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		deleteLinea(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculosLineas/deleteLinea'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getUsuariosAuto(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/getUsuariosAuto'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updateUsuariosAuto(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/user/updateUsuariosAuto'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/addVehiculos'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getMisVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/getMisVehiculos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("misVehiculos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getOfertasVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/getOfertasVehiculos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("ofertasVehiculos", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getPredios(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/predios/getPredios'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("predios", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		showPredio(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/predios/show'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("predios", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getPredioRedes(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/predios/getPredioRedes'
				delete params['url']
				axios.post(url, params).then(res => {
					//context.commit("predios", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		addPredio(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/predios/addPredio'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		updatePredio(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/predios/updatePredio'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		deletePredio(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/predios/deletePredio'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getVehiculo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getVehiculo'
				delete params['url']
				axios.post(url, params).then(res => {
					var data = res.data[0] || {}
					context.commit("infoVehiculo", data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoUpdateEstatusOferta(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/vehiculoUpdateEstatusOferta'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getVehiculosDisponibles(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getVehiculosDisponibles'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoUpdateStatus(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/vehiculoUpdateStatus'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		hipotecaUpdateStatus(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/vehiculoUpdateStatus'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoUpdate(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/vehiculoUpdate'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updateVehiculo(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/updateVehiculo'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoApartarVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/apartarVehiculos'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getApartarVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/getApartarVehiculos'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("apartados", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		updateApartarVehiculos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/updateApartarVehiculos'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getVehiculoOferta(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/getVehiculoOferta'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoOferta(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/vehiculoOferta'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoContacto(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/vehiculoContacto'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		vehiculoOfertaCredito(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/vehiculoOfertaCredito'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addInspeccion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/addInspeccion'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		addInspeccionHipoteca(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/inmuebles/addInspeccion'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInspeccion(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getInspeccion'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInspeccionInmueble(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/inmuebles/getInspeccion'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInspeccionInmuebleMedidas(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/inmuebles/getMedidas'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInmueblesFotos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/inmuebles/getInspeccionFotos'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInspeccionFotos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/vehiculos/getInspeccionFotos'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getAgenda(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/getAgenda'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		getInteres(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/vehiculos/getInteres'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		

		getTiendas(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tiendas/getTiendas'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getProveedores(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tiendas/getProveedores'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getProveedoresCategorias(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tiendas/getProveedoresCategorias'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
		
		getProductos(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tiendas/getProductos'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		showProducto(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tiendas/showProducto'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getDetalle(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/tiendas/getDetalle'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getSolicitudes(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tiendas/getSolicitudes'
				delete params['url']
				axios.post(url, params).then(res => {
					context.commit("tiendasSolicitudes", res.data)
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		addTiendasSolicitudes(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tiendas/addTiendasSolicitudes'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		deleteTiendasSolicitudes(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tiendas/deleteTiendasSolicitudes'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		showTiendasSolicitudes(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tiendas/showTiendasSolicitudes'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		updateTiendasSolicitudes(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/api/survey/tiendas/updateTiendasSolicitudes'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},

		getReviews(){
			return new Promise((resolve, reject) => {
				var url = '/apiv2/google/reviews'
				axios.post(url).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				})
			})
		},

		downloadFile(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/apiv2/inversiones/excel'
				delete params['url']
				axios({
					url: url,
					method: 'POST',
					data: params,
					responseType: 'blob'
				}).then((res) => {
					resolve(res)
				}).catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
				
			})
		},

		addPrendaPremium(context, params={}){
			return new Promise((resolve, reject) => {
				context.commit('showLoading')
				context.commit('errors', {})
				var url = params.url || '/apiv2/prendas/premium'
				delete params['url']
				axios.post(url, params).then(res => {
					resolve(res)
				})
				.catch(error => {
					reject(error)
				}).then(() => {
					context.commit('hideLoading')
				})
			})
		},
	},
	modules: {
	}
})
