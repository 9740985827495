export function getTokenApi() {
    const tokenStr = localStorage.getItem("token")

    if(!tokenStr) {
        return null
    }

    return tokenStr
}

export function getItems(){
    const items = localStorage.getItem("items")

    if (items) {
        if (typeof items === 'string') {
            return JSON.parse(items)
        }
    }
    return []
}

export function initialize(store, router, axios) {
	router.beforeEach((to, from, next) => {
        window.scrollTo(0, 0)
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
        const token = store.state.token
        document.title = (to.meta.title || '') + (to.meta.title ? ' | ' : '') + 'Club Cash-In'
        var user = store.getters.currentUser || {}
        
        if((to.name || []).includes('perfil') && user.prf == 3){
            next('/admin')
        } else if ((to.path == '/ingresar') && token) {
            if (user.prf != 3) {
                next('/admin')
            }else{
                next('/perfil')
            }
        } else if (requiresAuth && !token) {
            next('/ingresar')
        } else {
            next()
        }
        //store.commit('errors', {})
        var footerBG = 'bg-primary'
        var metaFooter = to.meta.footer
        if (metaFooter) {
            footerBG = metaFooter
        }else{
            if (user) {
                footerBG = 'bg-credito'
            }
        }
        store.commit('footerBG', footerBG)
        window.fbq('track', 'PageView')
    })

    axios.interceptors.response.use(null, (error) => {
        if (error.response) {
            if (error.response.status == 401) {
                store.commit('logout')
                if (router.currentRoute.value.name !== 'login') {
                    router.push({name: 'login'})
                }
            }
        }
        //showError(error)
        return Promise.reject(error)
    })
}