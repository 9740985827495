import {createApp} from 'vue/dist/vue.esm-bundler'

import App from './App.vue'
import router from './router'
import store from './store'
import {initialize} from './store/helpers'
import axios from 'axios'
import VueGtag from "vue-gtag"
import GAuth from 'vue3-google-oauth2'
import VueLazyload from 'vue-lazyload'
import LoadingComponent from './components/LoadingComponent.vue'

//import VueMeta from 'vue-3-meta'
initialize(store, router, axios)
window.$ = window.jQuery = require('jquery')
require('jquery-confirm')
require('bootstrap')
window.toastr = require('toastr')
window.url = process.env.NODE_ENV === 'production' ? 'https://clubcashin.com' : 'https://clubcashin.com'
window.codeGoogle = '654821936425-scuaqmav0q0td29v5cubc5b3krsauk6f.apps.googleusercontent.com'

var cashin = createApp(App)
cashin.component('LoadingComponent', LoadingComponent)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faLink, faEnvelope, faPhone, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar, faStarHalfStroke } from '@fortawesome/free-regular-svg-icons'
import { faYoutube, faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

//config.autoAddCss = false
library.add(faStar, faLink, faEnvelope, faPhone, faFileExcel, faFilePdf, farStar, faStarHalfStroke, faYoutube, faFacebook, faTwitter, faInstagram)

cashin.use(GAuth, {
	clientId: window.codeGoogle,
	scope: 'email',
	prompt: 'consent',
	fetch_basic_profile: false
})

const loadimage = require('./assets/image/loading-bars.svg')
const errorimage = require('./assets/image/image-empty.webp')
cashin.use(VueLazyload, {
	preLoad: 1.3,
	error: errorimage,
	loading: loadimage,
	attempt: 1
})

cashin.config.globalProperties.$filters = {
	currency(valor, symbol=true){
		var value = valor || 0
		//if (value=='') return ''
		let num = (value/1).toFixed(2)
		let val = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
		if (symbol) {
			return 'Q.' + val
		}
		return val
	},
	storage(url, size='500'){
		if(url){
			var rr = url.replace('https://clubcashin.com', window.url)
									.replace('https://www.clubcashin.com', window.url)
									.replace('http://192.168.42.77:9998', window.url)
									.replace(/ /g, '%20')
			return rr+'?w='+size
		}
	    return url
	},
	storageMin(url){
		if(url){
			//var ext = url.split('.').pop()
			var rr = url.replace('https://clubcashin.com', window.url)
									.replace('https://www.clubcashin.com', window.url)
									.replace('http://192.168.42.77:9998', window.url)
									.replace(/ /g, '%20')
			return rr
			//return rr.replace(`.${ext}`, `x200.${ext}`)
		}

		return url
	},

	url(url){
		var rr = url.toString().replace('https://clubcashin.com', window.url)
		rr = rr.replace('https://www.clubcashin.com', window.url)
		rr = rr.replace('http://192.168.42.77:9998', window.url)
		return rr
	},
	crm(url=''){
		if (process.env.NODE_ENV === 'production') {
			return `https://crmeasysale.com/cashin/${url}`
		}else{
			return `http://crm.test/${url}`
		}
	},
	date(value){
		if (!value) return ''
		return new Date(value).toLocaleDateString('es-GT', {
  		day: '2-digit',
  		month: '2-digit',
  		year: 'numeric',
  		timeZone: 'UTC'
  	})
	},
	dateTime(value){
		if (!value) return ''
		return (new Date(value).toLocaleDateString('es-GT', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: 'numeric',
			minute:'numeric',
			timeZone: 'UTC'
		})).replace(/[,]/g, '')
	},
	unixToDate(value){
		if (!value) return ''
  	return new Date(value*1000).toLocaleDateString('es-GT', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		})
	},
	slugToString(value, index = 0){
		if (!value) return ''

		if (index == 1) {
			return value.toUpperCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
		}
		
		return value.toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
	}
}

const mixins = {
	methods: {
		scrollToElement: function (scrollToMe = 'scrollToMe', focus) {
			const el = this.$refs[scrollToMe] || '';
			const input = this.$refs[focus] || '';

			if(input){
				input.focus()
			}

			if (el) {
				var scrolledY = el.offsetTop
				var yourHeight = 150;
				
				el.scrollIntoView({behavior: 'smooth'})
				if(scrolledY){
					//window.scroll(0, scrolledY-yourHeight)
					window.scroll({ top: (scrolledY-yourHeight), behavior: "smooth" });
				}
				//el.scrollIntoView({top: y, behavior: 'smooth'});
			}
		},
	},
}

//const tagId = process.env.NODE_ENV === 'production' ? 'AW-707917286' : 'UA-159974746-1'
const tagId = process.env.NODE_ENV === 'production' ? 'G-NQ5R1V12ZJ' : 'UA-159974746-1'
cashin.mixin(mixins).use(store).use(router).use(VueGtag, {
  config: { id: tagId }, //Produccion Cash In
  /*includes: [
  	{id: 'UA-159318910-1'}, //
  	{id: 'UA-159974746-1'}, // Pruebas desarrollo
  	{id: 'AW-707917286'}
  ]*/

}, router).mount('#app')